var copyLinkBtn = document.querySelectorAll(".copy-link");
var pageUrl = window.location.href;

for (var i = 0; i < copyLinkBtn.length; i++) {
  clickToCopy(copyLinkBtn[i], pageUrl);
}

function clickToCopy(button, textValue) {
  button.onclick = function() {
    navigator.clipboard.writeText(textValue);
  };
}