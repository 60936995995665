// TODO - This could be improved as it's a bit hard coded

$("input[value='charitable-company']").each(function() {
  const $this = $(this);
  $this.parent().append("<span class='radio-option-instructions'>A charitable company is an organisation that is both a registered company (usually a private company limited by guarantee) and a limited charity. All charities with an annual income over £5,000 are required to register with the Charity Commission.</span>");
});

$("input[value='ips-or-bencom']").each(function() {
  const $this = $(this);
  $this.parent().append("<span class='radio-option-instructions'>A community benefit society or 'bencom' is a form of co-operative that may be established by a geographical community or people with common interests.</span>");
});

// Add a back link to the 'first' page of the Formie form
if (!$(".eligibility-checker-form .fui-page[data-index='0']").is("[data-fui-page-hidden]")) {
  $("<a href='/funding/eligibility-checker/funding-programmes' class='fui-btn fui-prev'>Previous question</a>").insertAfter(".eligibility-checker-form .fui-page[data-index='0'] .fui-submit");
}