const navBreakpoint = 860;
const nav = $("#mainNav");
const mobileNavButton = $("#mobileNavButton");

//------------------------//
// Mobile expand-collapse //
//------------------------//

function collapseMobileMenu() {
  nav.slideUp("fast");
  mobileNavButton.removeAttr("aria-expanded");
  mobileNavButton.children("span").text("Menu");
  $(".nav-shade.mobile").css({ display: "none" });
}

function expandMobileMenu() {
  nav.slideDown("fast");
  mobileNavButton.attr("aria-expanded", "true");
  mobileNavButton.children("span").text("Close");
  $(".nav-shade.mobile").css({ display: "block" });
}

mobileNavButton.on("click", function () {
  // Collapse
  if (mobileNavButton.attr("aria-expanded")) {
    collapseMobileMenu();
  }

  // Expand
  else {
    expandMobileMenu();
  }
});

//-------------------------//
// Submenu expand-collapse //
//-------------------------//

function getViewportWidth() {
  return window.innerWidth;
}

function expandSubNav(topLevelLink) {
  topLevelLink.addClass("main-nav__top-level-a--active");
  topLevelLink.attr("aria-expanded", "true");
  topLevelLink.siblings().slideDown({
    duration: "fast",
    start: function () {
      $(this).css({ display: "flex" });
      $(".nav-shade.desktop").css({ display: "block" });
    },
  });
}

function collapseOtherSubNavs(topLevelLink) {
  const otherTopLevelLinks = topLevelLink
    .parent()
    .siblings()
    .children(".main-nav__top-level-a[aria-haspopup]");
  otherTopLevelLinks.each(function () {
    collapseSubNav($(this));
  });
}

function otherSubNavsAreOpen() {
  return $(".main-nav__top-level-a--active").length;
}

function collapseSubNav(topLevelLink) {
  topLevelLink.removeClass("main-nav__top-level-a--active");
  topLevelLink.attr("aria-expanded", "false");
  topLevelLink.siblings().slideUp("fast");
  $(".nav-shade.desktop").css({ display: "none" });
}

$(".main-nav__top-level-a[aria-haspopup]").on("click", function () {
  const $this = $(this);
  const isExpanded = $this.attr("aria-expanded");

  if (isExpanded == "false") {
    if (getViewportWidth() >= navBreakpoint && otherSubNavsAreOpen()) {
      collapseOtherSubNavs($this);
      setTimeout(function () {
        expandSubNav($this);
      }, 200);
    } else {
      collapseOtherSubNavs($this);
      expandSubNav($this);
    }
  } else {
    collapseSubNav($this);
  }
});

//-----------------------------//
// Resizing across breakpoints //
//-----------------------------//

var prevWindowWidth = 0;

$(document).ready(function () {
  prevWindowWidth = $(window).width();
});

function resizedWindow() {
  var currentWindowWidth = $(window).width();
  var desktopToMobile = prevWindowWidth > navBreakpoint && currentWindowWidth <= navBreakpoint;
  var mobileToDesktop = prevWindowWidth < navBreakpoint && currentWindowWidth >= navBreakpoint;

  prevWindowWidth = currentWindowWidth;

  // console.log("previous:", prevWindowWidth);
  // console.log("current:", currentWindowWidth);

  if (desktopToMobile) {
    // console.log("desktop to mobile");
    nav.css("display", "none");
  }

  // Mobile to desktop
  else if (mobileToDesktop) {
    // console.log("mobile to desktop");
    nav.css("display", "block");
  }

  // Breaking across either way
  if (desktopToMobile || mobileToDesktop) {
    mobileNavButton.removeAttr("aria-expanded");
    mobileNavButton.children("span").text("Menu");
    $(".main-nav__top-level-a[aria-haspopup]").each(function () {
      const $this = $(this);
      $this.removeClass("main-nav__top-level-a--active");
      $this.attr("aria-expanded", "false");
    });
    $(".main-nav__second-level").css("display", "none");
    $(".active-nav-item-triangle").css("display", "none");
  }
}

var doit;
$(window).resize(function () {
  clearTimeout(doit);
  doit = setTimeout(resizedWindow, 100);
});

//----------------------//
// Keyboard interaction //
//----------------------//

$(".main-nav__top-level-a").keydown(function(e) {
  const $this = $(this);
  const firstTopLevelItem = $(".main-nav__top-level-a").first();
  const lastTopLevelItem = $(".main-nav__top-level-a").last();
  const firstSubmenuItem = $this.siblings(".submenu").find("a[role='menuitem'").first();
  const isExpanded = $this.attr("aria-expanded");
  const code = e.keyCode || e.which;

  // Left
  if(code == 37) {
    if(isExpanded) {
      collapseSubNav($this);
    }

    const prevTopLevelItem = $this.parent().prev("li").children("a");
    if (prevTopLevelItem.length) {
      prevTopLevelItem.focus();

      if(isExpanded) {
        expandSubNav(prevTopLevelItem);
      }
    } else {
      lastTopLevelItem.focus();

      if(isExpanded) {
        expandSubNav(lastTopLevelItem);
      }
    }
  }

  // Right
  if(code == 39) {
    if (isExpanded) {
      collapseSubNav($this);
    }

    const nextTopLevelItem = $this.parent().next("li").children("a");
    if (nextTopLevelItem.length) {
      nextTopLevelItem.focus();

      if(isExpanded) {
        expandSubNav(nextTopLevelItem);
      }
    } else {
      firstTopLevelItem.focus();

      if(isExpanded) {
        expandSubNav(firstTopLevelItem);
      }
    }
  }

  // Escape
  if(code == 27) {
    collapseSubNav($this);
  }

  // Enter or space or down
  if(code == 13 || code == 32 || code == 40) {
    expandSubNav($this);
    firstSubmenuItem.focus();
  }

  // console.log(code);

  // Tab
  if(code != 9) {
    // $("#mainNav").next("*[tabindex != '-1']:visible").focus();
    return false;
  }

});

$(".submenu__item").keydown(function(e) {
  const $this = $(this);
  const directParent = $this.parent();
  const isLeadItem = directParent.hasClass("submenu__lead");
  const parentSubmenu = $this.parents(".submenu");
  const submenuItemsList = parentSubmenu.find(".submenu__items");
  const firstSubmenuItem = submenuItemsList.find(".submenu__item").first();
  const lastSubmenuItem = submenuItemsList.find(".submenu__item").last();
  const parentToplevelItem = $this.parents(".main-nav__top-level-item").children("a");
  const code = e.keyCode || e.which;

  // Up
  if(code == 38) {
    if(isLeadItem) {
      if(lastSubmenuItem.length) {
        lastSubmenuItem.focus();
      } else {
        directParent.siblings(".submenu__lead").children("a").focus();
      }
    } else {
      const prevSubmenuItem = $this.parent().prev("li").children("a");
      if (prevSubmenuItem.length) {
        prevSubmenuItem.focus();
      } else {
      $this.parents(".submenu").find(".submenu__item").first().focus();
      }
    }
  }

  // Down
  if(code == 40) {
    if(isLeadItem) {
      if(firstSubmenuItem.length) {
        firstSubmenuItem.focus();
      } else {
        directParent.siblings(".submenu__lead").children("a").focus();
      }
    } else {
      nextSubmenuItem = $this.parent().next("li").children("a");
      if (nextSubmenuItem.length) {
        nextSubmenuItem.focus();
      } else {
        $this.parents(".submenu").find(".submenu__item").first().focus();
      }
    }
  }

  // Left
  if(code == 37) {
    collapseSubNav(parentToplevelItem);

    const prevTopLevelItem = $this.parents(".main-nav__top-level-item").prev("li").children("a");
    if (prevTopLevelItem.length) {
      prevTopLevelItem.focus();
      expandSubNav(prevTopLevelItem);
    } else {
      const lastTopLevelItem = $this.parents("#mainNav").find(".main-nav__top-level-a").last();
      lastTopLevelItem.focus();
      expandSubNav(lastTopLevelItem);
    }
  }

  // Right
  if(code == 39) {
    collapseSubNav(parentToplevelItem);

    const nextTopLevelItem = $this.parents(".main-nav__top-level-item").next("li").children("a");
    if (nextTopLevelItem.length) {
      nextTopLevelItem.focus();
      expandSubNav(nextTopLevelItem);
    } else {
      const firstTopLevelItem = $this.parents("#mainNav").find(".main-nav__top-level-a").first();
      firstTopLevelItem.focus();
      expandSubNav(firstTopLevelItem);
    }
  }

  // Escape
  if(code == 27) {
    collapseSubNav(parentToplevelItem);
    parentToplevelItem.focus();
  }

  // console.log(code);

  // Enter
  if (code !== 13) {
    return false;
  }
});